
import { Component, Watch, Prop } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import {
  ICustomerStaffListRequest,
  ICustomerStaff
} from "@/types/customer_staff";
import { IPagination } from "@/types";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // customer_staff
  @Action("customer_staff/userGetList")
  public getList!: (request: ICustomerStaffListRequest) => Promise<boolean>;

  @Getter("customer_staff/list")
  public customer_staffList!: ICustomerStaff[];

  @Getter("customer_staff/pagination")
  public pagination!: IPagination<ICustomerStaff[]>;

  @Mutation("customer_staff/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.requestParams);
  }

  // prop
  @Prop({ default: false })
  public is_same_user!: boolean;

  // パラメータ定義
  public requestParams: ICustomerStaffListRequest = {
    page: 1,
    customer_id: 0
  };

  //
  // variables
  //
  public headers = [
    { text: "担当者名", value: "name", sortable: false },
    { text: "役職", value: "position", sortable: false },
    { text: "読み仮名", value: "kana", sortable: false },
    { text: "支店名", value: "branch_name", sortable: false },
    { text: "メール", value: "email", sortable: false },
    { text: "電話番号", value: "tel", sortable: false }
  ];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();

    await this.getList(this.requestParams);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const routeQuery = this.$route.query;
    const routeParams = this.$route.params;

    this.requestParams = {
      page: routeQuery.page ? Number(routeQuery.page) : 1,
      customer_id: routeParams.customer_id ? Number(routeParams.customer_id) : 0
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.requestParams.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.requestParams.page ? String(this.requestParams.page) : "1"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(customer_staff: ICustomerStaff) {
    if (this.is_same_user) {
      this.$router.push(`/user/customer_staff/${customer_staff.id}/edit`);
    }
  }
}
