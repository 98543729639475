
import { Component, Prop } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import CustomerStaffList from "@/components/user/list/CustomerStaffList.vue";
@Component({
  components: { CustomerStaffList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // prop
  @Prop({ default: false })
  public is_same_user!: boolean;

  public customer_id = 0;
  public created() {
    this.customer_id = Number(this.$route.params.customer_id);
  }
}
